// 手机号
const TelNoRegex = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
// 邮政编码
const ZipCodeRegex = /^[1-9]\d{5}$/

// 验证手机号
export const validatePhone = (telNo) => {
  return TelNoRegex.test(telNo);
}
// 验证邮编
export const validateZipCode = (zipCode) => {
  return ZipCodeRegex.test(zipCode);
}
