<template>
  <div class="address">
    <!-- <van-nav-bar title="地址编辑" left-arrow @click-left="onClickLeft" /> -->
    <van-address-edit
      :area-list="areaList"
      :address-info="address"
      show-delete
      show-set-default
      :tel-validator="validatePhone"
      tel-maxlength="11"
      :postal-validator="validateZipCode"
      area-placeholder="选择省 / 市 / 区"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { Toast } from 'vant';
  import { useRouter } from 'vue-router';
  import { validatePhone, validateZipCode } from '@/utils/VerifyUtil'
  import { areaList } from '@vant/area-data';
  var _=require("lodash");
  import { getAddress,saveAddress,delAddress } from "@/service/user"


  export default {
    setup() {
      const router = useRouter();
      let goods=router.currentRoute.value.query.goods;
      const storage= localStorage.getItem("token-hyxt");
      const token=JSON.parse(storage);
      const addrId = router.currentRoute.value.query.addrId ? router.currentRoute.value.query.addrId : 0;
      const address = ref({});
      const areaCode= ref(0);
      const loadData = async () => {
        if (addrId) {
          getAddress(addrId).then(res=>{
            address.value.name=res.data.data.conName;
            address.value.tel=res.data.data.conMobile;
            address.value.areaCode=areaCode;
            address.value.addressDetail=res.data.data.detail;
            address.value.isDefault=res.data.data.checked;
            areaCodeInit(res.data.data.area);
          });
        }
      }
      const onClickLeft = () => history.back();
      const onSave = (val) => {
        val.id=addrId;
        val.userId=token.userId;
        saveAddress(val).then(res =>{
          if(res.data.status==1){
            router.push({path: '/address', query: {goods:goods}});
          }
        })
      }
      //获取areaCode
      function areaCodeInit(area) {
        // 这里是点击编辑拿到的地址，我是用‘-’拼接起来的，现在分割一下
        var areaN = area.split(" ");
        var city = areaN[1]; //城市
        var district = areaN[2]; //区/县
        _.forEach(areaList.city_list, (o, c) => {
          if (o == city) {
            let cityId = String(_.take(c, 2));
            _.forEach(areaList.county_list, (i, a) => {
              if (i == district) {
                let districtId = String(_.take(a, 2));
                if (cityId == districtId) {
                  areaCode.value = a;
                }
              }
            });
          }
        });
      }

      const onDelete = () => {
        delAddress({id:addrId}).then(res=>{
          if(1==res.data.status){
            Toast("删除成功")
            router.push({path: '/address', query: {goods:goods}});
          }
        })
      }

      onMounted(() => {
        loadData();
      })

      return {
        address,
        onSave,
        onDelete,
        onClickLeft,
        validatePhone,
        validateZipCode,
        areaList,
      };
    }
  }
</script>

<style lang="scss">

</style>
